import './App.css';
import React from 'react';
import logo from './BorrowExchangeLogo.png';
import googlePlayImg from './google-play-badge256.png';
import appleStoreImg from './apple-store-badge.png';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Redirect } from 'react-router-dom'

function App() {
  return (
    <BrowserRouter>
      <Route path="/" exact render={
        () => { return(
          <div className="App">
          <header className="App-header">
            <h2>Borrow Exchange</h2>
            <img src={logo}  alt="logo" />
            <img src={googlePlayImg}></img>
            <img src={appleStoreImg}></img>
          </header>
        </div>
        ); }
      }></Route>
       <Route path="/app-store" exact render={
        () => { 
            return(   
                <Switch>
                    <Redirect to='/app-store.html' /> 
                </Switch>            
                       
            ); }
        }></Route>
            <Route path="/community-guidelines" exact render={
        () => { return(
            <div>
            <div className="App-my-header row justify-content-start">
                <div className="col-sm-2 align-self-center">
                    <img src={logo}  alt="logo" width="52px" />
                </div>
                <div className="col-sm-10 align-self-center">
                    <strong>Borrow Exchange</strong>
                </div>
            </div>
            
            <div className="jumbotron">
                <div className="container">
                <h3>Community Guidelines</h3>

<p align="LEFT">
    Borrow Exchange makes it easier than ever to find items to borrow in your neighborhood instead of buying. Every exchange requires trust.
    Accurately and fairly represent yourself and the items you’re lending or using as collateral on Borrow Exchange. Attempts to deceive or defraud users are not
    tolerated.
</p>
<p align="LEFT">
    All prohibited items listed throughout this guideline are not allowed to be borrowed, lent, or
    used as collateral.
</p>
<p align="LEFT">
    <strong>Prohibited conduct:</strong>
</p>
<ul>
    <li>
        <p align="LEFT">
            Attempts to lure users off of Borrow Exchange to other services are
            prohibited
        </p>
    </li>
    <li>
        <p align="LEFT">
            You may not circumvent our anti-spam and anti-fraud efforts (this
            includes multiple postings of the same item)
        </p>
    </li>
</ul>
<h2 align="LEFT">
    <strong>Borrow Exchange is more than an app, it’s a community.</strong>
</h2>
<p align="LEFT">
    We've built a community around helping neighbors in need. Treat others as
    you’d like to be treated and do what you say you will. Remember, most of
    the people you talk to on Borrow Exchange are your neighbors.
</p>
<p align="LEFT">
    <strong>Prohibited conduct:</strong>
</p>
<ul>
    <li>
        <p align="LEFT">
            Messages that are intended to harass, intimidate, threaten, or
            demean another user
        </p>
    </li>
    <li>
        <p align="LEFT">
            Items that promote or support hate groups
        </p>
    </li>
    <li>
        <p align="LEFT">
            Items that depict or glorify violence
        </p>
    </li>
    <li>
        <p align="LEFT">
            Racial slurs
        </p>
    </li>
</ul>
<h2 align="LEFT">
    <strong>Lend only items owned by you.</strong>
</h2>
<p align="LEFT">
    You must rightfully own anything you intend to lend on Borrow Exchange.
</p>
<h2 align="LEFT">
    <a name="4"></a>
    <strong>We follow the law, and you should too.</strong>
</h2>
<p align="LEFT">
    It’s your responsibility to understand all laws where you live and to make
    sure your activity on Borrow Exchange is legal. For example, you may need
    to make sure it’s legal for you to lend or borrow an item.
</p>
<p align="LEFT">
    <strong>Prohibited conduct:</strong>
</p>
<ul>
    <li>
        <p align="LEFT">
            Postings or messages that are defamatory, libelous, fraudulent or
            otherwise violate the law.
        </p>
    </li>
    <li>
        <p align="LEFT">
            Postings or messages that violate right of publicity or privacy.
        </p>
    </li>
    <li>
        <p align="LEFT">
            Posting or transmitting any virus, worm, Trojan horse, Easter egg,
            time bomb, spyware or other computer code, file or program that’s
            or is potentially harmful or invasive or intended to damage or
            hijack the operation of, or to monitor the use of, any hardware,
            software or equipment (each, a “Virus”).
        </p>
    </li>
    <li>
        <p align="LEFT">
            Using Borrow Exchange for any purpose that’s fraudulent or
            unlawful.
        </p>
    </li>
    <li>
        <p align="LEFT">
            Harvesting or collecting information about our users or directing
            our users to external websites or apps to harvest or collect their
            information.
        </p>
    </li>
    <li>
        <p align="LEFT">
            Interfering with or disrupting the operation of Borrow Exchange or
            our servers or networks.
        </p>
    </li>
    <li>
        <p align="LEFT">
            Restricting or inhibiting any other person from using Borrow
            Exchange.
        </p>
    </li>
    <li>
        <p align="LEFT">
            Reproducing, modifying, distributing or otherwise exploiting any
            portion of Borrow Exchange except as authorized by us.
        </p>
    </li>
    <li>
        <p align="LEFT">
            Reverse engineering, decompiling or disassembling any portion of
            Borrow Exchange.
        </p>
    </li>
    <li>
        <p align="LEFT">
            Systematically downloading our content.
        </p>
    </li>
    <li>
        <p align="LEFT">
            Unless you’re a public search engine, using any robot, spider, site
            search/retrieval application or other manual or automatic device to
            retrieve, index, “scrape,” “data mine” or otherwise gather our
            content.
        </p>
    </li>
    <li>
        <p align="LEFT">
            Asking a prospective lender or borrower questions about their sex,
            race, religion, sexual orientation, gender, or family status.
        </p>
    </li>
</ul>
<h2 align="LEFT">
    <a name="5"></a>
    <strong>Understand and respect our Prohibited Items guidelines:</strong>
</h2>
<p align="LEFT">
    Read and respect our prohibited items guidelines, but most importantly, use
    common sense.
</p>
<p align="LEFT">
    This list covers most of what isn’t allowed, but we’ll remove any item we
    feel is harmful to the Borrow Exchange community.
</p>
<h3 align="LEFT">
    <a name="6"></a>
    <strong>Adult content</strong>
</h3>
<p align="LEFT">
    Sexual content, adult toys or other items that include explicit nudity are
    not allowed on Borrow Exchange.
</p>
<p align="LEFT">
    <strong>Prohibited items include:</strong>
</p>
<ul>
    <li>
        <p align="LEFT">
            Pornography
        </p>
    </li>
    <li>
        <p align="LEFT">
            Adult Toys
        </p>
    </li>
</ul>
<h3 align="LEFT">
    <a name="7"></a>
    <strong>Drugs &amp; alcohol</strong>
</h3>
<p align="LEFT">
    Borrow Exchange isn’t a place to exchange drugs or alcohol, even if it’s
    legal to do so in your jurisdiction.
</p>
<p align="LEFT">
    <strong>Prohibited items include:</strong>
</p>
<ul>
    <li>
        <p align="LEFT">
            Alcoholic products
        </p>
    </li>
    <li>
        <p align="LEFT">
            Tobacco
        </p>
    </li>
    <li>
        <p align="LEFT">
            E-cigarettes and e-juice
        </p>
    </li>
    <li>
        <p align="LEFT">
            Illegal Drugs
        </p>
    </li>
    <li>
        <p align="LEFT">
            Prescription drugs or medical devices
        </p>
    </li>
    <li>
        <p align="LEFT">
            Over-the-counter drugs and supplements
        </p>
    </li>
</ul>
<ul>
</ul>
<h3 align="LEFT">
    <br/>
</h3>
<h3 align="LEFT">
    <a name="10"></a>
    <strong>Services</strong>
</h3>
<h3 align="LEFT">
    <strong>Prohibited services include:</strong>
</h3>
<ul>
    <li>
        <p align="LEFT">
            Financial Services (tax filing and preparation, insurance, loans,
            and credit scoring)
        </p>
    </li>
    <li>
        <p align="LEFT">
            Legal Services (debt collection, will preparation, legal advice)
        </p>
    </li>
    <li>
        <p align="LEFT">
            Medical Services (chiropractic care, cosmetic services like Botox)
        </p>
    </li>
    <li>
        <p align="LEFT">
            Escort Services, companionship, matchmaking
        </p>
    </li>
    <li>
        <p align="LEFT">
            Massage services
        </p>
    </li>
    <li>
        <p align="LEFT">
            Services which are themselves illegal (unlocking phones and other
            hardware) or may include illegal activity
        </p>
    </li>
    <li>
        <p align="LEFT">
            Services related to alcohol or drugs
        </p>
    </li>
    <li>
        <p align="LEFT">
            Services involving guns or other weapons
        </p>
    </li>
    <li>
        <p align="LEFT">
            Affiliate or pyramid schemes
        </p>
    </li>
</ul>
<h3 align="LEFT">
    <a name="11"></a>
    <strong>Spam</strong>
</h3>
<p align="LEFT">
    We don’t allow spam on our app. Don’t create multiple borrow requests of
    same items.
</p>
<p align="LEFT">
    <strong>Prohibited items include:</strong>
</p>
<ul>
    <li>
        <p align="LEFT">
            Repeated postings of the same item
        </p>
    </li>
    <li>
        <p align="LEFT">
            Mass messages sent without the intent of buying or selling items on
            Borrow Exchange
        </p>
    </li>
</ul>
<h3 align="LEFT">
    <a name="12"></a>
    <strong>Weapons and Other Dangerous Items</strong>
</h3>
<p align="LEFT">
    Your safety will always be Borrow Exchange’s top priority. That’s why we
    prohibit dangerous items like firearms, fireworks, or items that have been
    widely recalled.
</p>
<p align="LEFT">
    <strong>Prohibited items include:</strong>
</p>
<ul>
    <li>
        <p align="LEFT">
            All firearms, accessories and ammunition
        </p>
    </li>
    <li>
        <p align="LEFT">
            Airsoft, BB, or other novelty guns that do not clearly display an
            orange safety tip
        </p>
    </li>
    <li>
        <p align="LEFT">
            Tasers
        </p>
    </li>
    <li>
        <p align="LEFT">
            Burglary Tools
        </p>
    </li>
    <li>
        <p align="LEFT">
            Fireworks
        </p>
    </li>
    <li>
        <p align="LEFT">
            Pesticides, tear gas, and other hazardous chemicals or controlled
            substances
        </p>
    </li>
</ul>
<h3 align="LEFT">
    <br/>
</h3>
<h3 align="LEFT">
    <a name="13"></a>
    <strong>Miscellaneous</strong>
</h3>
<p align="LEFT">
    The policies above cover most of what isn’t allowed, but are not
    exhaustive. We’ll remove any item we feel is harmful to the Borrow Exchange
    community.
</p>
<p align="LEFT">
    Other prohibited items that are commonly posted are listed below.
</p>
<p align="LEFT">
    <strong>Prohibited items include:</strong>
</p>
<ul>
    <li>
        <p align="LEFT">
            Lottery tickets and other sweepstakes or gambling
        </p>
    </li>
    <li>
        <p align="LEFT">
            Items obtained through government assistance
        </p>
    </li>
    <li>
        <p align="LEFT">
            Bodily fluids such as breast milk
        </p>
    </li>
</ul>

                </div>
            </div>
            </div>
            ); }
        }></Route>
      <Route path="/privacy-policy" exact render={
        () => { return(
            <div>
            <div className="App-my-header row justify-content-start">
                <div className="col-sm-2 align-self-center">
                    <img src={logo}  alt="logo" width="52px" />
                </div>
                <div className="col-sm-10 align-self-center">
                    <strong>Borrow Exchange</strong>
                </div>
            </div>
            <div className="jumbotron">
                <div className="container">
                <h2>PRIVACY POLICY</h2>

<p>
    <em>Last updated June 08, 2019 </em>
</p>
<p>
    Thank you for choosing to be part of our community at Borrow Exchange
    (&#8220;Company&#8221;, &#8220;we&#8221;, &#8220;us&#8221;, or &#8220;our&#8220;).
    We respect your privacy and are committed to protecting your personal
    information. Please read this privacy policy before using our Services.
</p>
<p>
    When you visit our mobile application, and use our services, you trust us
    with your personal information. We take your privacy very seriously. In
    this privacy notice, we describe our privacy policy. We seek to explain to
    you in the clearest way possible what information we collect, how we use it
    and what rights you have in relation to it. We hope you take some time to
    read through it carefully, as it is important. If there are any terms in
    this privacy policy that you do not agree with, please discontinue use of
    our Apps and our services. This privacy policy applies to all information
    collected through our mobile application, ("Apps"), and/or any related
    services, sales, marketing or events (we refer to them collectively in this
    privacy policy as the "Sites"). Please read this privacy policy carefully
    as it will help you make informed decisions about sharing your personal
    information with us.
</p>
<p>
    <strong>TABLE OF CONTENTS</strong>
</p>
<p>
    1. WHAT INFORMATION DO WE COLLECT?
</p>
<p>
    2. HOW DO WE USE YOUR INFORMATION?
</p>
<p>
    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
</p>
<p>
    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
</p>
<p>
    5. DO WE USE GOOGLE MAPS?
</p>
<p>
    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
</p>
<p>
    7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
</p>
<p>
    8. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
</p>
<p>
    9. HOW LONG DO WE KEEP YOUR INFORMATION?
</p>
<p>
    10. HOW DO WE KEEP YOUR INFORMATION SAFE?
</p>
<p>
    <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
</p>
<p>
    <em><strong>Information automatically collected </strong></em>
</p>
<p>
    In Short: Some information &#8211; such as IP address and/or browser and
    device characteristics &#8211; is collected automatically when you visit
    our Apps. We automatically collect certain information when you visit, use
    or navigate the Apps. This information does not reveal your specific
    identity (like your name or contact information) but may include device and
    usage information, such as your IP address, browser and device
    characteristics, operating system, language preferences, referring URLs,
    device name, country, location, information about how and when you use our
    Apps and other technical information. This information is primarily needed
    to maintain the security and operation of our Apps, and for our internal
    analytics and reporting purposes. Like many businesses, we also collect
    information through cookies and similar technologies.
</p>
<p>
    <em><strong>Information collected through our Apps </strong></em>
</p>
<p>
    In Short: We may collect information regarding your geolocation, mobile
    device, push notifications, when you use our apps.
</p>
<p>
    <strong>2. HOW DO WE USE YOUR INFORMATION? </strong>
</p>
<p>
    In Short: We process your information for purposes based on legitimate
    business interests, the fulfillment of our contract with you, compliance
    with our legal obligations, and/or your consent. We use personal
    information collected via our Apps for a variety of business purposes
    described below. We process your personal information for these purposes in
    reliance on our legitimate business interests, in order to enter into or
    perform a contract with you, with your consent, and/or for compliance with
    our legal obligations. We indicate the specific processing grounds we rely
    on next to each purpose listed below. We use the information we collect or
    receive:
</p>
<p>
    To facilitate account creation and logon process.
</p>
<p>
    If you choose to link your account with us to a third party account *(such
    as your Google or Facebook account), we use the information you allowed us
    to collect from those third parties to facilitate account creation and
    logon process. See the section below headed "HOW DO WE HANDLE YOUR SOCIAL
    LOGINS" for further information.
</p>
<p>
    To enable user-to-user communications.
</p>
<p>
    We may use your information in order to enable user-to-user communications
    with each user's consent.
</p>
<p>
    To enforce our terms, conditions and policies.
</p>
<p>
    To respond to legal requests and prevent harm. If we receive a subpoena or
    other legal request, we may need to inspect the data we hold to determine
    how to respond.
</p>
<p>
    <strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
</p>
<p>
    In Short: We only share information with your consent, to comply with laws,
    to protect your rights, or to fulfill business obligations.
</p>
<p>
    We may process or share data based on the following legal basis:
</p>
<p>
    Consent: We may process your data if you have given us specific consent to
    use your personal information in a specific purpose.
</p>
<p>
    Legitimate Interests: We may process your data when it is reasonably
    necessary to achieve our legitimate business interests.
</p>
<p>
    Performance of a Contract: Where we have entered into a contract with you,
    we may process your personal information to fulfill the terms of our
    contract.
</p>
<p>
    Legal Obligations: We may disclose your information where we are legally
    required to do so in order to comply with applicable law, governmental
    requests, a judicial proceeding, court order, or legal process, such as in
    response to a court order or a subpoena (including in response to public
    authorities to meet national security or law enforcement requirements).
</p>
<p>
    Vital Interests: We may disclose your information where we believe it is
    necessary to investigate, prevent, or take action regarding potential
    violations of our policies, suspected fraud, situations involving potential
    threats to the safety of any person and illegal activities, or as evidence
    in litigation in which we are involved.
</p>
<p>
    More specifically, we may need to process your data or share your personal
    information in the following situations:
</p>
<p>
    Vendors, Consultants and Other Third-Party Service Providers. We may share
    your data with third party vendors, service providers, contractors or
    agents who perform services for us or on our behalf and require access to
    such information to do that work. Examples include: payment processing,
    data analysis, email delivery, hosting services, customer service and
    marketing efforts. We may allow selected third parties to use tracking
    technology on the Apps, which will enable them to collect data about how
    you interact with the Apps over time. This information may be used to,
    among other things, analyze and track data, determine the popularity of
    certain content and better understand online activity. Unless described in
    this Policy, we do not share, sell, rent or trade any of your information
    with third parties for their promotional purposes.
</p>
<p>
    Business Transfers. We may share or transfer your information in connection
    with, or during negotiations of, any merger, sale of company assets,
    financing, or acquisition of all or a portion of our business to another
    company.
</p>
<p>
    Business Partners. We may share your information with our business partners
    to offer you certain products, services or promotions.
</p>
<p>
    Other Users. When you share personal information (for example, by posting
    comments, contributions or other content to the Apps) or otherwise interact
    with public areas of the Apps, such personal information may be viewed by
    all users and may be publicly distributed outside the Apps in perpetuity.
    If you interact with other users of our Apps and register through a social
    network (such as Facebook), your contacts on the social network will see
    your name, profile photo, and descriptions of your activity. Similarly,
    other users will be able to view descriptions of your activity, communicate
    with you within our Apps, and view your profile.
</p>
<p>
    Offer Wall. Our Apps may display a third-party hosted &#8220;offer
    wall.&#8221; Such an offer wall allows third-party advertisers to offer
    virtual currency, gifts, or other items to users in return for acceptance
    and completion of an advertisement offer. Such an offer wall may appear in
    our mobile application and be displayed to you based on certain data, such
    as your geographic area or demographic information. When you click on an
    offer wall, you will leave our mobile application. A unique identifier,
    such as your user ID, will be shared with the offer wall provider in order
    to prevent fraud and properly credit your account.
</p>
<p>
    <strong>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? </strong>
</p>
<p>
    In Short: We may use cookies and other tracking technologies to collect and
    store your information. We may use cookies and similar tracking
    technologies (like web beacons and pixels) to access or store information.
    Specific information about how we use such technologies and how you can
    refuse certain cookies is set out in our Cookie Policy.
</p>
<p>
    <strong>5. DO WE USE GOOGLE MAPS?</strong>
</p>
<p>
    In Short: Yes, we use Google Maps for the purpose of providing better
    service. This website, mobile application, or Facebook application uses
    Google Maps APIs. You may find the Google Maps APIs Terms of Service here.
    To better understand Google&#8217;s Privacy Policy, please refer to this
    link. By using our Maps API Implementation, you agree to be bound by
    Google&#8217;s Terms of Service. By using our implementation of the Google
    Maps APIs, you agree to allow us to gain access to information about you
    including personally identifiable information (such as usernames) and
    non-personally identifiable information (such as location). We will be
    collecting the following information:
</p>
<ul>
    <li>
        <p>
            User's selected location
        </p>
    </li>
</ul>
<p>
    For a full list of what we use information for, please see the previous
    section titled "HOW DO WE USE YOUR INFORMATION?" and &#8220;WILL YOUR
    INFORMATION BE SHARED WITH ANYONE?&#8221; You agree to allow us to obtain
    or cache your location. You may revoke your consent at anytime. The Maps
    APIs that we use store and access cookies and other information on your
    devices. If you are a user currently in the European Union, please take a
    look at our EU User Consent Policy.
</p>
<p>
    <strong>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
</p>
<p>
    In Short: If you choose to register or log in to our websites using a
    social media account, we may have access to certain information about you.
    Our Apps offer you the ability to register and login using your third party
    social media account details (like your Facebook or Twitter logins). Where
    you choose to do this, we will receive certain profile information about
    you from your social media provider. The profile Information we receive may
    vary depending on the social media provider concerned, but will often
    include your name, e-mail address, friends list, profile picture as well as
    other information you choose to make public. We will use the information we
    receive only for the purposes that are described in this privacy policy or
    that are otherwise made clear to you on the Apps. Please note that we do
    not control, and are not responsible for, other uses of your personal
    information by your third party social media provider. We recommend that
    you review their privacy policy to understand how they collect, use and
    share your personal information, and how you can set your privacy
    preferences on their sites and apps.
</p>
<p>
    <strong>7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? </strong>
</p>
<p>
    In Short: We may transfer, store, and process your information in countries
    other than your own.
</p>
<p>
    Our servers are located in United States. If you are accessing our Apps
    from outside United States, please be aware that your information may be
    transferred to, stored, and processed by us in our facilities and by those
    third parties with whom we may share your personal information (see "WILL
    YOUR INFORMATION BE SHARED WITH ANYONE? " above), in United States, and
    other countries.
</p>
<p>
    If you are a resident in the European Economic Area, then these countries
    may not have data protection or other laws as comprehensive as those in
    your country. We will however take all necessary measures to protect your
    personal information in accordance with this privacy policy and applicable
    law.
</p>
<p>
    EU-U.S. Privacy Shield Framework:
</p>
<p>
    In particular Company complies with the EU-U.S. Privacy Shield Framework as
    set forth by the U.S. Department of Commerce regarding the collection, use,
    and retention of personal information transferred from the European Union
    to the United States and has certified its compliance with it. As such,
    Company is committed to subjecting all personal information received from
    European Union (EU) member countries, in reliance on the Privacy Shield
    Framework, to the Framework&#8217;s applicable Principles. To learn more
    about the Privacy Shield Framework, visit the U.S. Department of
    Commerce&#8217;s Privacy Shield List.
</p>
<p>
    Company is responsible for the processing of personal information it
    receives, under the Privacy Shield Framework, and subsequently transfers to
    a third party acting as an agent on its behalf.
</p>
<p>
    With respect to personal information received or transferred pursuant to
    the Privacy Shield Framework, Company is subject to the regulatory
    enforcement powers of the U.S. FTC. In certain situations, we may be
    required to disclose personal information in response to lawful requests by
    public authorities, including to meet national security or law enforcement
    requirements.
</p>
<p>
    <strong>8. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</strong>
</p>
<p>
    In Short: We are not responsible for the safety of any information that you
    share with third-party providers who advertise, but are not affiliated
    with, our websites.
</p>
<p>
    The Apps may contain advertisements from third parties that are not
    affiliated with us and which may link to other websites, online services or
    mobile applications. We cannot guarantee the safety and privacy of data you
    provide to any third parties. Any data collected by third parties is not
    covered by this privacy policy. We are not responsible for the content or
    privacy and security practices and policies of any third parties, including
    other websites, services or applications that may be linked to or from the
    Apps. You should review the policies of such third parties and contact them
    directly to respond to your questions
</p>
<p>
    <strong>9. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
</p>
<p>
    In Short: We keep your information for as long as necessary to fulfill the
    purposes outlined in this privacy policy unless otherwise required by law.
</p>
<p>
    We will only keep your personal information for as long as it is necessary
    for the purposes set out in this privacy policy, unless a longer retention
    period is required or permitted by law (such as tax, accounting or other
    legal requirements). No purpose in this policy will require us keeping your
    personal information for longer than the period of time in which users have
    an account with us.
</p>
<p>
    When we have no ongoing legitimate business need to process your personal
    information, we will either delete or anonymize it, or, if this is not
    possible (for example, because your personal
</p>
<p>
    <strong>10. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
</p>
<p>
    In Short: We aim to protect your personal information through a system of
    organizational and technical security measures.
</p>
<p>
    We have implemented appropriate technical and organizational security
    measures designed to protect the security of any personal information we
    process. However, please also remember that we cannot guarantee that the
    internet itself is 100% secure. Although we will do our best to protect
    your personal information, transmission of personal information to and from
    our Apps is at your own risk. You should only access the services within a
    secure environment.
</p>
<p>
    <strong>11. DO WE COLLECT INFORMATION FROM MINORS?</strong>
</p>
<p>
    In Short: We do not knowingly collect data from or market to children under
    18 years of age.
</p>
<p>
    We do not knowingly solicit data from or market to children under 18 years
    of age. By using the Apps, you represent that you are at least 18 or that
    you are the parent or guardian of such a minor and consent to such minor
    dependent's use of the Apps. If we learn that personal information from
    users less than 18 years of age has been collected, we will deactivate the
    account and take reasonable measures to promptly delete such data from our
    records. If you become aware of any data we have collected from children
    under age 18, please contact us at dpo@borrowexchange.com.
</p>
<p>
    <strong>12. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
</p>
<p>
    In Short: In some regions, such as the European Economic Area, you have
    rights that allow you greater access to and control over your personal
    information. You may review, change, or terminate your account at any time.
</p>
<p>
    In some regions (like the European Economic Area), you have certain rights
    under applicable data protection laws. These may include the right (i) to
    request access and obtain a copy of your personal information, (ii) to
    request rectification or erasure; (iii) to restrict the processing of your
    personal information; and (iv) if applicable, to data portability. In
    certain circumstances, you may also have the right to object to the
    processing of your personal information. To make such a request, please use
    the contact details provided below. We will consider and act upon any
    request in accordance with applicable data protection laws.
</p>
<p>
    If we are relying on your consent to process your personal information, you
    have the right to withdraw your consent at any time. Please note however
    that this will not affect the lawfulness of the processing before its
    withdrawal.
</p>
<p>
    If you are resident in the European Economic Area and you believe we are
    unlawfully processing your personal information, you also have the right to
    complain to your local data protection supervisory authority. You can find
    their contact details here:<br/>
    <a href="http://ec.europa.eu/justice/dataprotection/bodies/authorities/index_en.htm">
        http://ec.europa.eu/justice/dataprotection/bodies/authorities/index_en.htm
    </a>
</p>
<p>
    Account Information
</p>
<p>
    If you would at any time like to review or change the information in your
    account or terminate your account, you can:
</p>
<ul>
    <li>
        <p>
            Log into your account settings and update your user account.
        </p>
    </li>
</ul>
<p>
    Upon your request to terminate your account, we will deactivate or delete
    your account and information from our active databases. However, some
    information may be retained in our files to prevent fraud, troubleshoot
    problems, assist with any investigations, enforce our Terms of Use and/or
    comply with legal requirements.
</p>
<p>
    Opting out of email marketing: You can unsubscribe from our marketing email
    list at any time by clicking on the unsubscribe link in the emails that we
    send or by contacting us using the details provided below. You will then be
    removed from the marketing email list &#8211; however, we will still need
    to send you service-related emails that are necessary for the
    administration and use of your account. To otherwise opt-out, you may:
</p>
<ul>
    <li>
        <p>
            Note your preferences when you register an account with the site.
        </p>
    </li>
    <li>
        <p>
            Access your account settings and update preferences.
        </p>
    </li>
</ul>
<p>
    <strong>13. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
</p>
<p>
    Most web browsers and some mobile operating systems and mobile applications
    include a Do-Not-Track (&#8220;DNT&#8221;) feature or setting you can
    activate to signal your privacy preference not to have data about your
    online browsing activities monitored and collected. No uniform technology
    standard for recognizing and implementing DNT signals has been finalized.
    As such, we do not currently respond to DNT browser signals or any other
    mechanism that automatically communicates your choice not to be tracked
    online. If a standard for online tracking is adopted that we must follow in
    the future, we will inform you about that practice in a revised version of
    this Privacy Policy.
</p>
<p>
    <strong>14. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong>
</p>
<p>
    In Short: Yes, if you are a resident of California, you are granted
    specific rights regarding access to your personal information.
</p>
<p>
    California Civil Code Section 1798.83, also known as the &#8220;Shine The
    Light&#8221; law, permits our users who are California residents to request
    and obtain from us, once a year and free of charge, information about
    categories of personal information (if any) we disclosed to third parties
    for direct marketing purposes and the names and addresses of all third
    parties with which we shared personal information in the immediately
    preceding calendar year. If you are a California resident and would like to
    make such a request, please submit your request in writing to us using the
    contact information provided below.
</p>
<p>
    If you are under 18 years of age, reside in California, and have a
    registered account with the Apps, you have the right to request removal of
    unwanted data that you publicly post on the Apps. To request removal of
    such data, please contact us using the contact information provided below,
    and include the email address associated with your account and a statement
    that you reside in California. We will make sure the data is not publicly
    displayed on the Apps, but please be aware that the data may not be
    completely or comprehensively removed from our systems.
</p>
<p>
    <strong>15. DO WE MAKE UPDATES TO THIS POLICY?</strong>
</p>
<p>
    In Short: Yes, we will update this policy as necessary to stay compliant
    with relevant laws. We may update this privacy policy from time to time.
    The updated version will be indicated by an updated &#8220;Revised&#8221;
    date and the updated version will be effective as soon as it is accessible.
    If we make material changes to this privacy policy, we may notify you
    either by prominently posting a notice of such changes or by directly
    sending you a notification. We encourage you to review this privacy policy
    frequently to be informed of how we are protecting your information.
</p>
<p>
    <strong>16. HOW CAN YOU CONTACT US ABOUT THIS POLICY? </strong>
</p>
<p>
    If you have questions or comments about this policy, you may contact our
Data Protection Officer (DPO), Tyler Andersen, by email at    <a href="mailto:dpo@borrowexchange.app">dpo@borrowexchange.app</a>, by
    phone at +1 (480) 331-8580, or by post to: 1846 E. Innovation Park Dr., STE
    100, ORO VALLEY, AZ 85755
</p>
<p>
    Borrow Exchange, LLC
</p>
<p>
    Tyler Andersen
</p>
                </div>
            </div>
</div>

        ); }
      }></Route>

<Route path="/terms-of-service" exact render={
        () => { return(
            <div>
            <div className="App-my-header row justify-content-start">
                <div className="col-sm-2 align-self-center">
                    <img src={logo}  alt="logo" width="52px" />
                </div>
                <div className="col-sm-10 align-self-center">
                    <strong>Borrow Exchange</strong>
                </div>
            </div>
            <div className="jumbotron">
                <div className="container">
                <h2>TERMS OF SERVICE</h2>
<p>
    <em>Last Updated: January 2, 2020</em>
</p>
<p>
    Welcome to the Borrow Exchange service! Please read these Terms of Service
    (the “Agreement”) carefully. Your use of the Borrow Exchange app and/or
    website constitutes your consent to this Agreement.
</p>
<p>
    This Agreement is between you and Borrow Exchange, LLC concerning your use
    of or access to the Borrow Exchange mobile application. Borrow Exchange,
    LLC is referred to in this Agreement as “Company” or “we” or “us”. The
    Borrow Exchange mobile application, together with any successor app(s) will
    be referred to as the “App” and the Borrow Exchange website currently
    located at www.borrowexchange.app together with any successor website, will
    be referred to as the “Site.” Finally, this Agreement refers to the App and
    Site, together with any materials and services available therein, as the
    “Service.” Please note that this Agreement hereby incorporates by reference
    any additional terms and conditions we post through the Service or
    otherwise make available to you.
</p>
<p>
    BY USING THE SERVICE, YOU AFFIRM THAT YOU ARE EITHER (1) OF LEGAL AGE TO
    ENTER INTO THIS AGREEMENT (WHICH IS 18 IN MOST STATES), OR, (2) AT LEAST 14
    YEARS OLD AND THAT YOU HAVE OBTAINED PARENTAL OR GUARDIAN CONSENT TO ENTER
    INTO THIS AGREEMENT, AND THAT YOUR PARENT OR GUARDIAN HAS AGREED TO BE
    LIABLE FOR YOUR ACTS AND OMISSIONS. BY USING THIS SERVICE YOU AGREE THAT WE ARE NOT RESPONSIBLE FOR MONITORING COMMUNICATIONS, 
    BUT RESERVE THE RIGHT TO DO SO. WE ARE NOT A PARTY TO ANY TRANSACTION THAT OCCURS BETWEEN A LENDER AND A RENTER/BORROWER. 
    BY USING THE SERVICE, YOU ACKNOWLEDGE AND AGREE THAT WE ARE NOT RESPONSIBLE FOR, AND WE EXPRESSLY DISCLAIM, 
    ANY AND ALL LIABILITY THAT MAY RESULT FROM ANY INFORMATION PROVIDED BY A LENDER OR RENTER/BORROWER, 
    THE PROVISION OR USE OF ANY ITEM OR THE CONDUCT, ONLINE OR OFFLINE, OF ANY USER OF THE SERVICE. 
    BORROW EXCHANGE DOES NOT SPONSOR, ENDORSE OR RECOMMEND ANY LENDER OR RENTER/BORROWER, 
    NOR DO WE MAKE ANY REPRESENTATIONS OR WARRANTIES ABOUT THE QUALITY, AVAILABILITY OR LEGALITY OF ANY ITEM. 
    ADDITIONALLY, WE CANNOT AND DO NOT REPRESENT OR WARRANT THAT ANY LENDER IS LICENSED, QUALIFIED, 
    INSURED OR CAPABLE OF PROVIDING ANY ITEM, AND WE MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY, 
    RELIABILITY OR ACCURACY OF ANY ITEM OR ANY INFORMATION PROVIDED BY A LENDER OR A RENTER/BORROWER. 
    IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN 
    RELIANCE ON ANY INFORMATION PROVIDED BY A LENDER OR RENTER/BORROWER. BY USING THE SERVICE, 
    YOU ACKNOWLEDGE AND AGREE THAT WE ARE NOT RESPONSIBLE FOR, AND WE EXPRESSLY DISCLAIM, 
    ANY AND ALL LIABILITY THAT MAY RESULT FROM ANY TRANSACTION OR EXCHANGE BETWEEN LENDER AND RENTER/BORROWER.  
    YOU ACKNOWLEDGE AND AGREE THAT WE ARE NOT RESPONSIBLE FOR, AND WE EXPRESSLY DISCLAIM, ANY AND ALL LIABILITY THAT MAY 
    RESULT FROM USE OF LENT/RENTED ITEM. 
</p>
<p>
    THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION THAT, AS FURTHER
    SET FORTH IN SECTION 19 BELOW, REQUIRES THE USE OF ARBITRATION ON AN
    INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR ANY OTHER
    COURT PROCEEDINGS, OR CLASS ACTIONS OF ANY KIND
</p>
<p>
    1. Information Submitted Through the Service
</p>
<p>
    Your submission of information through the Service is governed by Company's
    Privacy Policy <a href="https://www.borrowexchange.app/privacy-policy">borrowexchange.app/privacy-policy</a> (the “Privacy
    Policy”). You represent and warrant that any information you provide in
    connection with the Service, including Listings and other Submissions
    (each, as defined below), is and will remain accurate and complete, and
    that you will maintain and update such information as needed.
</p>
<p>
    2. Community Guidelines.
</p>
<p>
    You agree that you will comply with all of our Community Guidelines, as
    updated by us from time to time, located at <a href="https://www.borrowexchange.app/community-guidelines">borrowexchange.app/community-guidelines</a>
    . The Community
    Guidelines contain, among other things, our list of prohibited products,
    prohibited services and our rules of conduct.
</p>
<p>
    3. Registration; User Names and Passwords.
</p>
<p>
    You may need to register to use all or part of the Service. We may reject,
    or require that you change, any user name, password or other information
    that you provide to us in registering. Your user name and password are your
    account credentials and must be kept confidential. You are responsible for
    any and all activity on any account you register using your account
    credentials. You must immediately notify us if you believe that your
    account credentials have been compromised. If you register using a social
    media network, you authorize us to access, use, disclose and retain, in
    accordance with our Privacy Policy, the information that we receive from
    the social media network in connection with your registration.
</p>
<p>
    4. Listings.
</p>
<p>
    The Service includes a forum where users may post and browse listings for
    goods, , real estate and services (each a “Listing”). Such Listings are
    provided by users, and not by us. WE DO NOT SELL, RENT OR OTHERWISE MAKE
    AVAILABLE ANY GOODS, REAL ESTATE OR SERVICES. WE ARE NOT RESPONSIBLE OR
    LIABLE FOR ANY SUCH LISTINGS, FOR ANY TRANSACTIONS LISTINGS (EXCEPT AS SET
    FORTH IN THIS AGREEMENT) AND SUCH LISTINGS DO NOT CONSTITUTE AN OFFER FROM
    THE COMPANY. PLEASE USE CAUTION AND COMMON SENSE WHEN BUYING, SELLING,
    LEASING, HIRING OR OTHERWISE PARTICIPATING IN ANY TRANSACTION REGARDING A
    LISTING, OR MAKING OR RECEIVING PAYMENT FOR ANY LISTING.
</p>
<p>
    The availability or promotion of a Listing through the Service does not
    imply our endorsement of the Listing or the provider. We make no
    representations whatsoever about any Listings, or any users. Information
    about and the availability of any Listing are subject to change at any time
    without notice. You must ascertain and obey all applicable laws regarding
    the marketing, promotion, sale, purchase, delivery, receipt, possession and
    use of the subject of any Listing. We reserve the right to remove any
    Listing for any reason.
</p>
<p>
    You represent and warrant that you have the necessary authority and
    permissions to post any Listing,to enter into any transaction or to make
    any transfer relating to a Listing, including, if you are not of legal age
    to do so, by obtaining proper parental or guardian consent. The legal age
    is 18 in most states.
</p>
<p>
    5. Submissions.
</p>
<p>
    You may submit information that could be used to personally identify you
    through Listings, the creation of profile pages, submissions in forums,
    connection via a social network, message boards, chat, comments, or various
    other interactive channels of the Service(each, a “Submission”). You are
    responsible for any Submission you make. We have no control over and are
    not responsible for any use or misuse (including any distribution) by any
    third party of your Submissions.
</p>
<p>
    6. License.
</p>
<p>
    You retain ownership of your Submissions but for each Submission you make
    you hereby grant to us a worldwide, royalty-free, fully paid-up,
    non-exclusive, perpetual, irrevocable, transferable and fully sublicensable
    (through multiple tiers) license, without additional consideration to you
    or any third party, to reproduce, distribute, perform and display (publicly
    or otherwise), create derivative works of, adapt, modify and otherwise use,
    analyze and exploit each Submission, in any format or media now known or
    hereafter developed, and for any purpose (including promotional purposes,
    such as testimonials).
</p>
<p>
    In addition, if you provide to us any ideas, proposals, suggestions or
    other materials (“Feedback”), whether related to the Service or otherwise,
    such Feedback will be deemed a Submission, and you hereby acknowledge and
    agree that such Feedback is not confidential and that your provision of
    such Feedback is gratuitous, unsolicited and made without restriction, and
    does not create any obligation whatsoever by us.
</p>
<p>
    You represent and warrant that you have all right and title necessary to
    grant the licenses granted in this section, and that your Submissions, and
    your provision thereof through and in connection with the Service, are
    complete and accurate, and are not fraudulent, tortious or otherwise in
    violation of any applicable law or any right of any third party. You
    further irrevocably waive any “moral rights” or other rights with respect
    to attribution of authorship or integrity of materials regarding each
    Submission that you may have under any applicable law under any legal
    theory.
</p>
<p>
    7. Monitoring.
</p>
<p>
    We may (but have no obligation to) monitor (either directly or using
    software), evaluate, alter or remove Submissions before or after they
    appear on the Service, or analyze your access to or use of the Service.
    Subject to our Privacy Policy, you agree that we may disclose any and all
    information regarding your access to and use of the Service to anyone for
    any reason or purpose.
</p>
<p>
    8. Featured Listings and Subscriptions.
</p>
<p>
    We may provide certain enhanced features (such as the featuring your
    Listing in the feed) for a fee (“Fees”) in connection with the Service
    (“Paid Services”). All Fees are set out and described in the App and are
    denominated in U.S. dollars and are exclusive of any applicable taxes.
    Payments are processed through the Google Play or iTunes app store from
    which you originally downloaded the App. You may access the applicable
    “in-app” purchase rules and policies directly from the app stores. All
    billing and refund inquiries must be directed to the applicable app store.
    Delivery of the Paid Services is provided immediately upon purchase.
</p>
<p>
    Certain of the Paid Services may be offered on a subscription basis with
    auto-renewing payments (“Subscription Services”). The billing period for
    each type of subscription service will be as specified in the App or Site
    at the time of purchase. You can manage your subscriptions from your
    account in the Google Play or iTunes app store. To cancel any Subscription
    Service, you must submit your cancellation through the applicable app
    store. You must cancel a Subscription Service before the start of the next
    billing period in order to avoid charges for the next billing period’s
    fees. Following any cancellation, you will continue to have access to the
    Subscription Services through the end of your current billing period and
    then the Subscription Service will terminate automatically. If you delete
    the app or the account on which the subscription was applied, the
    subscription will be fully consumed, even if it is time-dependent and the
    billing period has not elapsed.
</p>
<p>
    We reserve the right to modify the Fees from time to time in our sole
    discretion. Any changes in the Fees will apply to the next billing period.
    Except as provided above, or as otherwise expressly agreed upon by us, all
    sales of Paid Services (including any Subscription Services) are final and
    there are no refunds. There are no refunds or credits for partially used
    Subscription Services periods.
</p>
<p>
    10. Your Limited Rights.
</p>
<p>
    The App is licensed (not sold) to end users. Subject to your compliance
    with this Agreement, and solely for so long as you are permitted by Company
    to use the App, we hereby permit you, on a limited, non-exclusive,
    revocable, non-transferable, non-sublicensable basis, to install and use
    the App on a mobile device that you own or control, solely for your use. If
    you fail to comply with any of the terms or conditions of this Agreement,
    (i) you must immediately cease using the App, (ii) you must remove (that
    is, uninstall and delete) the App from your mobile device and (iii) you are
    prohibited from using the App on any mobile device until such time as we
    grant you explicit permission to use the App again. Please note that if you
    do not accept the system permissions that the App requires from time to
    time, you may not be able to use the App or certain of its functionalities.
    You are responsible for obtaining, maintaining and paying for all hardware
    and all telecommunications and other services needed to use the Service.
</p>
<p>
    In addition, subject to your compliance with this Agreement, and solely for
    so long as you are permitted by Company to use the Site, you may view one
    (1) copy of any portion of the Site to which we provide you access under
    this Agreement, on any single device, solely for your personal,
    non-commercial use.
</p>
<p>
    11. Company's Proprietary Rights.
</p>
<p>
    We own the Service, which is protected by proprietary rights and laws. Our
    trade names, trademarks and service marks include Borrow Exchange and any
    associated logos. All trade names, trademarks, service marks and logos on
    the Service not owned by us are the property of their respective owners.
    You may not use our trade names, trademarks, service marks or logos in
    connection with any product or service that is not ours, or in any manner
    that is likely to cause confusion. Nothing contained on the Service should
    be construed as granting any right to use any trade names, trademarks,
    service marks or logos without the express prior written consent of the
    owner.
</p>
<p>
    12. Third Party Materials.
</p>
<p>
    Though your use of the Service, you may have access to certain information,
    products, services and other materials made available by third parties,
    including Listings and other Submissions (“Third Party Materials”), or
    allow for the routing or transmission of such Third Party Materials.
</p>
<p>
    We do not control or endorse, and are not responsible for, any Third Party
    Materials and make no representations or warranties about them. We may not
    monitor Third Party Materials, and we may block or disable access to any
    Third Party Materials through the Service at any time. The availability of
    any Third Party Materials through the Service does not imply our
    endorsement of, or our affiliation with, any provider of Third Party
    Materials.
</p>
<p>
    YOU USE THIRD PARTY MATERIALS AT YOUR OWN RISK AND IS SUBJECT TO ANY
    ADDITIONAL TERMS OR POLICIES APPLICABLE TO SUCH THIRD PARTY MATERIALS (SUCH
    AS TERMS OF SERVICE OR PRIVACY POLICIES OF THE PROVIDERS OF SUCH THIRD
    PARTY MATERIALS).
</p>
<p>
    13. Promotions.
</p>
<p>
    Any sweepstakes, contests, raffles, surveys, games or similar promotions
    (collectively, “Promotions”) made available through the Service may be
    governed by rules that are separate from this Agreement. If you participate
    in any Promotions, please review the applicable rules. If the rules for a
    Promotion conflict with this Agreement, the Promotion rules will govern.
</p>
<p>
    14. Disclaimer of Warranties.
</p>
<p>
    TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) THE SERVICE AND
    ANY THIRD PARTY MATERIALS ARE MADE AVAILABLE TO YOU BY US ON AN “AS IS,”
    “WHERE IS” AND “WHERE AVAILABLE” BASIS, WITHOUT ANY WARRANTIES BY COMPANY
    OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY; AND (B) COMPANY
    DISCLAIMS ALL WARRANTIES WITH RESPECT TO THE SERVICE AND ANY LISTINGS AND
    THIRD PARTY MATERIALS, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS
    FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND TITLE. ALL DISCLAIMERS OF
    ANY KIND (INCLUDING IN THIS SECTION AND ELSEWHERE IN THIS AGREEMENT) ARE
    MADE FOR THE BENEFIT OF BOTH COMPANY AND ITS AFFILIATES AND THEIR
    RESPECTIVE SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES,
    AGENTS, REPRESENTATIVES, LICENSORS, SUPPLIERS AND SERVICE PROVIDERS
    (COLLECTIVELY, THE “AFFILIATED ENTITIES”), AND THEIR RESPECTIVE SUCCESSORS
    AND ASSIGNS.
</p>
<p>
    We do not guarantee that the Service is or will remain available updated,
    complete, correct, secure, or that access to the Service will be
    uninterrupted. The Service may include inaccuracies, errors, and materials
    that violate or conflict with this Agreement. Additionally, third parties
    may make unauthorized alterations to the Service. If you become aware of
    any such alteration, contact us at info@borrowexchange.app with a
    description of such alteration and its location on the Service.
</p>
<p>
    15. Limitation of Liability.
</p>
<p>
    TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) COMPANY WILL NOT
    BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY
    OR PUNITIVE DAMAGES OF ANY KIND, UNDER ANY CONTRACT, TORT (INCLUDING
    NEGLIGENCE), STRICT LIABILITY OR OTHER THEORY, INCLUDING DAMAGES FOR LOSS
    OF PROFITS, USE OR DATA, LOSS OF OTHER INTANGIBLES, LOSS OF SECURITY OF
    SUBMISSIONS (INCLUDING UNAUTHORIZED INTERCEPTION BY THIRD PARTIES OF ANY
    SUBMISSIONS), EVEN IF ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES
    OR LOSSES; (B) WITHOUT LIMITING THE FOREGOING, COMPANY WILL NOT BE LIABLE
    FOR DAMAGES OF ANY KIND RESULTING FROM YOUR USE OF OR INABILITY TO USE THE
    SERVICE OR FROM ANY LISTINGS OR TRANSACTIONS OR TRANSFERS RELATING TO
    LISTINGS, OR FROM ANY THIRD PARTY MATERIALS, INCLUDING FROM ANY DISPUTE
    WITH ANY OTHER USER OF THE SERVICE; (C) YOUR SOLE AND EXCLUSIVE REMEDY FOR
    DISSATISFACTION WITH THE SERVICE OR ANY THIRD PARTY MATERIALS IS TO STOP
    USING THE SERVICE; AND (D) THE MAXIMUM AGGREGATE LIABILITY OF COMPANY FOR
    ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT
    (INCLUDING NEGLIGENCE) OR OTHERWISE, SHALL BE THE GREATER OF (I) THE TOTAL
    AMOUNT, IF ANY, PAID BY YOU TO COMPANY TO USE THE SERVICE, AND (II)
    TWENTY-FIVE U.S. DOLLARS ($25.00). ALL LIMITATIONS OF LIABILITY OF ANY KIND
    (INCLUDING IN THIS SECTION AND ELSEWHERE IN THIS AGREEMENT) ARE MADE FOR
    THE BENEFIT OF BOTH COMPANY AND THE AFFILIATED ENTITIES, AND THEIR
    RESPECTIVE SUCCESSORS AND ASSIGNS.
</p>
<p>
    16. Indemnity.
</p>
<p>
    To the fullest extent permitted under applicable law, you agree to defend,
    indemnify and hold harmless Company and the Affiliated Entities, and their
    respective successors and assigns, from and against all claims,
    liabilities, damages, judgments, awards, losses, costs, expenses and fees
    (including attorneys’ fees) arising out of or relating to (a) your use of,
    or activities in connection with, the Service (including all Submissions);
    (b) any Listings, transactions or transfers relating to Listings, or
    disputes with other users of the Service; and (c) any violation or alleged
    violation of this Agreement by you.
</p>
<p>
    17. Termination.
</p>
<p>
    This Agreement is effective until terminated. The company may terminate or
    suspend your use of the Service at any time and without prior notice, for
    any or no reason. Upon any such termination or suspension, your right to
    use the Service will immediately cease, and Company may take technical
    and/or other measures to block your access to the Service. Sections [2–3,
    9, 12–13 and 15–24] shall survive any expiration or termination of this
    Agreement.
</p>
<p>
    18. Governing Law; Arbitration.
</p>
<p>
    The terms of this Agreement are governed by the laws of the United States
    (including federal arbitration law) and the State of New York, without
    regard to its principles of conflicts of law, and regardless of your
    location. EXCEPT FOR DISPUTES THAT QUALIFY FOR SMALL CLAIMS COURT, ALL
    DISPUTES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY ASPECT OF THE
    RELATIONSHIP BETWEEN YOU AND COMPANY, WHETHER BASED IN CONTRACT, TORT,
    STATUTE, FRAUD, MISREPRESENTATION OR ANY OTHER LEGAL THEORY, WILL BE
    RESOLVED THROUGH FINAL AND BINDING ARBITRATION BEFORE A NEUTRAL ARBITRATOR
    INSTEAD OF IN A COURT BY A JUDGE OR JURY AND YOU AGREE THAT COMPANY AND YOU
    ARE EACH WAIVING THE RIGHT TO TRIAL BY A JURY. EXCEPT AS PROVIDED BELOW
    REGARDING THE CLASS ACTION WAIVER, SUCH DISPUTES INCLUDE, WITHOUT
    LIMITATION, DISPUTES ARISING OUT OF OR RELATING TO INTERPRETATION OR
    APPLICATION OF THIS ARBITRATION PROVISION, INCLUDING THE ENFORCEABILITY,
    REVOCABILITY OR VALIDITY OF THE ARBITRATION PROVISION OR ANY PORTION OF THE
    ARBITRATION PROVISION. ALL SUCH MATTERS SHALL BE DECIDED BY AN ARBITRATOR
    AND NOT BY A COURT OR JUDGE. HOWEVER, AS SET FORTH BELOW, THE PRECEDING
    ARBITRATION REQUIREMENT SHALL NOT APPLY TO DISPUTES TO THE EXTENT RELATING
    TO THE INTERPRETATION OR APPLICATION OF THE CLASS ACTION WAIVER BELOW,
    INCLUDING ITS ENFORCEABILITY, REVOCABILITY OR VALIDITY.
</p>
<p>
    YOU AGREE THAT ANY ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN
    INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED
    AND YOU ARE AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS
    ACTION. Notwithstanding anything to the contrary in this Section or any
    other provision of this Agreement or in the American Arbitration
    Association’s Consumer Arbitration Rules, disputes regarding the
    enforceability, revocability or validity of the foregoing class action
    waiver may be resolved only by a civil court of competent jurisdiction and
    not by an arbitrator. In any case in which (1) the dispute is filed as a
    class, collective, or representative action, and (2) there is a final
    judicial determination that all or part of such class action waiver is
    unenforceable, then the class, collective, and/or representative action, to
    that extent, must be litigated in a civil court of competent jurisdiction,
    but the portion of such class action waiver that is enforceable shall be
    enforced in arbitration.
</p>
<p>
    The arbitration will be administered by the American Arbitration
    Association under its Consumer Arbitration Rules, as amended by this
    Agreement. The Consumer Arbitration Rules are available online
    https://www.adr.org/consumer. The arbitrator will conduct hearings, if any,
    by teleconference or video conference, rather than by personal appearances,
    unless the arbitrator determines upon request by you or by us that an
    in-person hearing is appropriate. Any in-person appearances will be held at
    a location which is reasonably convenient to both parties with due
    consideration of their ability to travel and other pertinent circumstances.
    If the parties are unable to agree on a location, such determination should
    be made by the AAA or by the arbitrator. The arbitrator’s decision will
    follow the terms of this Agreement and will be final and binding. The
    arbitrator will have authority to award temporary, interim or permanent
    injunctive relief or relief providing for specific performance of this
    Agreement, but only to the extent necessary to provide relief warranted by
    the individual claim before the arbitrator. The award rendered by the
    arbitrator may be confirmed and enforced in any court having jurisdiction
    thereof. Notwithstanding any of the foregoing, nothing in this Agreement
    will preclude you from bringing issues to the attention of federal, state
    or local agencies and, if the law allows, they can seek relief against us
    for you.
</p>
<p>
    <strong>19. Information or Complaints.</strong>
</p>
<p>
    If you have a question or complaint regarding the Service, please send an
    e-mail to info@borrowexchange.app. You may also contact us by writing to
    1846 E. Innovation Park Dr., STE 100, ORO VALLEY, AZ 85755 or by calling us
    at +1 (480) 331-8580. Please note that e-mail communications will not
    necessarily be secure; accordingly, you should not include credit card
    information or other sensitive information in your e-mail correspondence
    with us. California residents may reach the Complaint Assistance Unit of
    the Division of Consumer Services of the California Department of Consumer
    Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or by
    telephone at (916) 445-1254 or (800) 952-5210.
</p>
<p>
    <strong>20. Copyright Infringement Claims.</strong>
</p>
<p>
    The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides a
    process for copyright owners who believe that material appearing on the
    Internet infringes their rights under U.S. copyright law. If you believe in
    good faith that materials available on the Service infringe your copyright,
    you (or your agent) may send the Company a written notice by mail, e-mail
    or fax, requesting that the Company remove or block access to such
    material. If you believe in good faith that someone has wrongly filed a
    notice of copyright infringement against you, the DMCA permits you to send
    to Company a counter-notice. Notices and counter-notices must meet the
    statutory requirements imposed of the DMCA. See http://www.copyright.gov/
    for details. Notices and counter-notices must be sent in writing to our
    DMCA Agent as follows: By mail to Borrow Exchange DMCA Agent, 1846 E.
    Innovation Park Dr., STE 100, ORO VALLEY, AZ 85755; by e-mail to
    info@borrowexchange.app.
</p>
<p>
    We suggest that you consult your legal advisor before filing a DMCA notice
    or counter-notice.
</p>
<p>
    <strong>21. Export Controls.</strong>
</p>
<p>
    You are responsible for complying with United States export controls and
    for any violation of such controls, including any United States embargoes
    or other federal rules and regulations restricting exports. You represent,
    warrant and covenant that you are not (a) located in, or a resident or a
    national of, any country subject to a U.S. government embargo or other
    restriction, or that has been designated by the U.S. government as a
    “terrorist supporting” country; or (b) on any of the U.S. government lists
    of restricted end users.
</p>
<p>
    <strong>22. Jurisdictional Issues.</strong>
</p>
<p>
    The Service is controlled or operated (or both) from the United States, and
    is not intended to be subject Company to any non-U.S. jurisdiction or law.
    The Service may not be appropriate or available for use in some non-U.S.
    jurisdictions. We may limit the Service’s availability at any time, in
    whole or in part, to any person, geographic area or jurisdiction that we
    choose.
</p>
<p>
    <strong>23. Changes.</strong>
</p>
<p>
    We may change this Agreement from time to time by notifying you of such
    changes by any reasonable means, including by posting a revised Agreement
    through the Service. Any such changes will not apply to any dispute between
    you and us arising prior to the date on which we posted the revised
    Agreement incorporating such changes, or otherwise notified you of such
    changes.
</p>
<p>
    Your use of the Service following any changes to this Agreement will
    constitute your acceptance of such changes. The “Last Updated” legend above
    indicates when this Agreement was last changed. We may, at any time and
    without liability, modify or discontinue all or part of the Service;
    charge, modify or waive any fees required to use the Service; or offer
    opportunities to some or all Service users.
</p>
<p>
    <strong>24. Miscellaneous.</strong>
</p>
<p>
    This Agreement does not, and shall not be construed to, create any
    partnership, joint venture, employer-employee, agency or
    franchisor-franchisee relationship between you and Company. If any
    provision of this Agreement is found to be unlawful, void or for any reason
    unenforceable, that provision will be deemed severable from this Agreement
    and will not affect the validity and enforceability of any remaining
    provision. You may not assign, transfer or sublicense any or all of your
    rights or obligations under this Agreement without our express prior
    written consent. We may assign, transfer or sublicense any or all of our
    rights or obligations under this Agreement without restriction. No waiver
    by either party of any breach or default under this Agreement will be
    deemed to be a waiver of any preceding or subsequent breach or default. Any
    heading, caption or section title contained herein is for convenience only,
    and in no way defines or explains any section or provision. All terms
    defined in the singular shall have the same meanings when used in the
    plural, where appropriate and unless otherwise specified. Any use of the
    term “including” or variations thereof in this Agreement shall be construed
    as if followed by the phrase “without limitation.” This Agreement,
    including any terms and conditions incorporated herein, is the entire
    agreement between you and Company relating to the subject matter hereof,
    and supersedes any and all prior or contemporaneous written or oral
    agreements or understandings between you and Company relating to such
    subject matter. Notices to you (including notices of changes to this
    Agreement) may be made via posting to the Service or by e-mail (including
    in each case via links), or by regular mail. Without limitation, a printed
    version of this Agreement and of any notice given in electronic form shall
    be admissible in judicial or administrative proceedings based upon or
    relating to this Agreement to the same extent and subject to the same
    conditions as other business documents and records originally generated and
    maintained in printed form. The company will not be responsible for any
    failure to fulfill any obligation due to any cause beyond its control.
</p>
<p>
    <strong>25. Apple-Specific Terms.</strong>
</p>
<p>
    In addition to your agreement with the foregoing terms and conditions, and
    notwithstanding anything to the contrary herein, the following provisions
    apply with respect to your use of any version of the App compatible with
    the iOS operating system of Apple Inc. (“Apple”). Apple is not a party to
    this Agreement and does not own and is not responsible for the App. Apple
    is not providing any warranty for the App except, if applicable, to refund
    the purchase price for it. Apple is not responsible for maintenance or
    other support services for the App and shall not be responsible for any
    other claims, losses, liabilities, damages, costs or expenses with respect
    to the App, including any third-party product liability claims, claims that
    the App fails to conform to any applicable legal or regulatory requirement,
    claims arising under consumer protection or similar legislation, and claims
    with respect to intellectual property infringement. Any inquiries or
    complaints relating to the use of the App, including those pertaining to
    intellectual property rights, must be directed to Company in accordance
    with the “Information or Complaints” section above. The license you have
    been granted herein is limited to a non-transferable license to use the App
    on an Apple-branded product that runs Apple’s iOS operating system and is
    owned or controlled by you, or as otherwise permitted by the Usage Rules
    set forth in Apple’s App Store Terms of Service. In addition, you must
    comply with the terms of any third-party agreement applicable to you when
    using the App, such as your wireless data service agreement. Apple and
    Apple’s subsidiaries are third-party beneficiaries of this Agreement and,
    upon your acceptance of the terms and conditions of this Agreement, will
    have the right (and will be deemed to have accepted the right) to enforce
    this Agreement against you as a third-party beneficiary thereof;
    notwithstanding the foregoing, Company’s right to enter into, rescind or
    terminate any variation, waiver or settlement under this Agreement is not
    subject to the consent of any third party.
</p>
</div>
            </div>
</div>

        ); }
      }></Route>
    </BrowserRouter>
  );
}

export default App;
